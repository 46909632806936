




















import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import SubdivisionType from '../../../../store/entities/subdivisiontype'

@Component
export default class SubdivisionTypeForm extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    @Prop({type: Boolean, default: false}) public toEdit: boolean;
    subdivisionType: SubdivisionType = new SubdivisionType();

    created() {
    }

    save() {
        (this.$refs.subdivisionTypeForm as any).validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: this.toEdit ? 'subdivisiontype/update' : 'subdivisiontype/create',
                    data: this.subdivisionType
                });
                (this.$refs.subdivisionTypeForm as any).resetFields();
                this.$emit('save-success');
                this.$emit('input', false);
            }
        })
    }

    cancel() {
        (this.$refs.subdivisionTypeForm as any).resetFields();
        this.$emit('input', false);
    }

    visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            if (this.toEdit) {
                this.subdivisionType = Util.extend(true, {}, this.$store.state.subdivisiontype.editSubdivisionType);
            } else {
                this.subdivisionType = new SubdivisionType();
            }
        }
    }

    subdivisionTypeRule = {
        name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}]
    }
}
